.contact-container {
  background-color: var(--secondary-color);
  padding: 75px 0px;
  .container {
    .card-wrapper {
      margin-top: 40px;
      display: flex;
      gap: 103px;
      .info-card {
        .card-image {
          width: 140px;
          height: 140px;
          img {
            height: 100%;
          }
          .border {
            border-radius: 10px;
            border: 1px solid #21bf61;
          }
        }
        .contact-link {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
          border-bottom: 2px solid var(--primary-color);
          width: max-content;
          margin-top: 26px;

          #link {
            color: var(--primary-color) !important;
            font-family: "Henderson BCG Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%; /* 20.8px */
          }
          img {
            width: 16px;
            height: 12px;
          }
        }
        .contact-header {
          margin-top: 20px;
        }
      }
    }
  }
}
