.footer {
  background-color: var(--dark-color);
  width: 100%;
  .footer-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 37px 0px;
    .footer-container {
      display: flex;
      flex-direction: column;
      gap: 26px;
      .email-container {
        display: flex;
        gap: 8px;
        align-items: center;
        img {
          width: 22px;
          height: 22px;
        }
      }
      .footer-links {
        display: flex;
        gap: 33px;
      }
    }
    .footer-logo {
      img {
        width: 66px;
        height: 27px;
      }
    }
  }
}
