.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
  margin-top: 50px;
  position: relative;
  right: 20px;

  .btn-pagination {
    padding: 0px;
    background: transparent;
    img {
      width: 40px;
      height: 40px;
    }
  }
  .page-number {
    .active-page {
      color: var(--black-color);
      font-family: "Henderson BCG Sans";
      font-size: 32px;
      font-style: normal;
      font-weight: 300;
      line-height: 120%;
    }
    .total-pages {
      color: #b1b1b1;
      font-family: "Henderson BCG Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
    }
  }
}
