.banner-container {
  margin-top: 130px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;

  .ellipse1 {
    position: absolute;
    display: none;

    @media only screen and (min-width: 1200px) {
      display: flex;
      top: -63px;
      right: 0;
      width: 279px;
    }
  }

  .banner-image-container {
    display: flex;
    flex-direction: column;
    width: 60%;

    .banner-image {
      align-self: flex-end;
      width: 568px;
      height: 504px;
    }

    .plantfacts-logo {
      width: 306px;
      height: 99px;
      align-self: flex-start;
    }
  }

  .banner-details {
    width: 34%;

    p {
      margin-bottom: 20px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .video-container .imageList-list .images-wrapper {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 891px) {
  .container {
    .banner-container {
      margin-bottom: 40px;

      .plantfacts-logo {
        width: 150px;
        height: 50px;
      }

      .banner-image-container {
        .banner-image {
          width: 368px;
          height: 304px;
          margin-top: 30px;
        }
      }
    }

    .glance-container {
      flex-direction: column;

      .plant-detail {
        width: 100%;
      }

      .plant-details-container {
        margin-top: 20px;
        width: 100%;
      }
    }
  }

  .video-container .container .video-wrapper {
    grid-template-columns: 1fr !important;
    padding: 0px 20px !important;
  }

  .video-container {
    .container {
      .video-wrapper {
        grid-template-columns: 1fr;
      }
    }

    .images-wrapper {
      display: grid !important;
      grid-template-columns: 1fr 1fr !important;

      .video-image {
        width: 80%;
      }

      .video-image:nth-child(2)>p {
        border: none !important;
      }

      .video-image:nth-child(4)>p {
        border: none !important;
      }
    }
  }
}

@media only screen and (max-width: 781px) {
  .header {
    padding-right: 30px;
  }

  .container {
    padding: 0px 20px;

    .banner-container {
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .banner-image-container {
        width: 100%;
        align-items: center;

        .banner-image {
          width: 70%;
          height: 66%;
          align-self: center;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .banner-details {
        width: 100%;
      }
    }

    .glance-container {
      flex-direction: column;
      padding: 20px;

      .plant-detail {
        width: 100%;
      }

      .plant-details-container {
        width: 100%;
      }

      .grid-3 {
        grid-template-columns: 1fr 1fr;
        gap: 40px;
        align-items: baseline;
      }
    }
  }

  .guide-container {
    .container {

      /* .guide-title {
        text-align: center;
      } */
      .guide-wrapper {
        flex-direction: column;
        width: 100%;

        .guide-card {
          width: 100%;
        }
      }
    }
  }

  .video-container {
    margin-top: 35px !important;

    .container {
      .video-wrapper {
        grid-template-columns: 1fr;
      }
    }

    .images-wrapper {
      display: grid !important;
      grid-template-columns: 1fr !important;
      padding-left: 40px !important;

      .video-image {
        width: 100% !important;
      }

      .video-image>p {
        border: none !important;
      }
    }
  }

  .backdrop {
    .modal-container {
      width: 90%;
    }
  }
}

@media only screen and (max-width: 480px) {
  .header {
    padding-right: 20px;
  }

  .container {
    padding: 0px 20px;

    .glance-container {
      padding: 10px 20px;
      flex-direction: column;

      .plant-detail {
        width: 100%;
      }

      .plant-details-container {
        width: 100%;
      }

      .grid-3 {
        grid-template-columns: 1fr;
      }
    }
  }

  .guide-container {
    .container {
      .guide-wrapper {
        flex-direction: column;
        width: 100%;

        .guide-card {
          width: 100%;
          height: 260px;
        }
      }
    }
  }

  div.testimonials-container {
    .container {
      .border-line {
        display: none;
      }

      .testimonials-wrapper .testimonial:first-child {
        border-bottom: 1px solid #a1a1a1;
        margin-bottom: 10px;
        padding-bottom: 10px;
      }

      .testimonials-wrapper {
        flex-direction: column;

        .testimonial {
          width: 100%;

          .description {
            font-size: 16px;
          }
        }
      }
    }
  }

  div.contact-container .container .card-wrapper {
    flex-direction: column;
    gap: 75px;
  }

  div .footer .footer-wrapper {
    align-items: flex-start;

    .footer-container .footer-links {
      flex-wrap: wrap;

      li .text-lightDark {
        display: none;
      }
    }
  }

  .backdrop {
    .modal-container {
      width: 100%;
      bottom: -20px;
      padding-bottom: 30px;
      top: auto;
      border-radius: 24px 24px 0px 0px;

      .modal-wrapper .modal-header {
        padding-left: 20px;
      }

      .modal-wrapper .form-container {
        flex-direction: column;
        gap: 24px;
        margin-top: 36px;

        input {
          width: 94%;
        }

        .btn-wraper {
          justify-content: center;
        }
      }
    }
  }
}