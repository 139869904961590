:root {
  --primary-color: #197a56;
  --secondary-color: #dcf9e3;
  --dark-color: #323232;
  --light-color: #f4f4f4;
  --black-color: #000;
  --lightDark: #d4d4d4;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

@font-face {
  font-family: "Henderson BCG Sans";
  src: local("Henderson BCG Sans Italic"), local("HendersonBCGSans-Italic"),
    url("../../assets/fonts/HendersonBCGSans-Italic.woff2") format("woff2"),
    url("../../assets/fonts/HendersonBCGSans-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Henderson BCG Sans";
  src: local("Henderson BCG Sans Thin"), local("HendersonBCGSans-Thin"),
    url("../../assets/fonts/HendersonBCGSans-Thin.woff2") format("woff2"),
    url("../../assets/fonts/HendersonBCGSans-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Henderson BCG Sans";
  src: local("Henderson BCG Sans Bold"), local("HendersonBCGSans-Bold"),
    url("../../assets/fonts/HendersonBCGSans-Bold.woff2") format("woff2"),
    url("../../assets/fonts/HendersonBCGSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Henderson BCG Sans";
  src: local("Henderson BCG Sans Bold Italic"),
    local("HendersonBCGSans-BoldItalic"),
    url("../../assets/fonts/HendersonBCGSans-BoldItalic.woff2") format("woff2"),
    url("../../assets/fonts/HendersonBCGSans-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Henderson BCG Sans";
  src: local("Henderson BCG Sans Light"), local("HendersonBCGSans-Light"),
    url("../../assets/fonts/HendersonBCGSans-Light.woff2") format("woff2"),
    url("../../assets/fonts/HendersonBCGSans-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Henderson BCG Sans";
  src: local("Henderson BCG Sans Light Italic"),
    local("HendersonBCGSans-LightItalic"),
    url("../../assets/fonts/HendersonBCGSans-LightItalic.woff2") format("woff2"),
    url("../../assets/fonts/HendersonBCGSans-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Henderson BCG Sans";
  src: local("Henderson BCG Sans"), local("HendersonBCGSans-Regular"),
    url("../../assets/fonts/HendersonBCGSans-Regular.woff2") format("woff2"),
    url("../../assets/fonts/HendersonBCGSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Henderson BCG Sans";
  src: local("Henderson BCG Sans Thin Italic"),
    local("HendersonBCGSans-ThinItalic"),
    url("../../assets/fonts/HendersonBCGSans-ThinItalic.woff2") format("woff2"),
    url("../../assets/fonts/HendersonBCGSans-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}

body {
  font-family: "Henderson BCG Sans";
  color: #333;
  line-height: 1.6;
}

ul {
  list-style-type: none;
}

li {
  text-decoration: none;
  color: var(--light-color) !important;
  font-family: "Henderson BCG Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

h1,
h2 {
  font-weight: 300;
  line-height: 1.2;
  margin: 10px 0;
}

p {
  margin: 12px 0;
}

img {
  width: 100%;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 40px;
}

.card {
  background-color: #fff;
  color: #333;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin: 10px;
}

.btn {
  display: inline-flex;
  padding: 12px 40px 12px 32px;
  cursor: pointer;
  color: #fff;
  font-family: "Henderson BCG Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  border-radius: 35px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  img {
    width: 16px;
    height: 16px;
  }

  transition: all 30ms ease-in-out;
}

.btn-outline {
  background-color: transparent;
  border: 1px #fff solid;
}

.btn:hover {
  transform: scale(1.2);
}

/* Backgrounds & colored buttons */
.bg-primary,
.btn-primary {
  background-color: var(--primary-color);
  color: #fff;
}

.bg-secondary,
.btn-secondary {
  background-color: var(--secondary-color);
  color: #fff;
}

.bg-dark,
.btn-dark {
  background-color: #b1b1b1;
  color: #fff;
  color: var(--Grays-Gray-500, #696969);
  font-family: "Henderson BCG Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.bg-light,
.btn-light {
  background-color: var(--light-color);
  color: #333;
}

.bg-primary a,
.btn-primary a,
.bg-secondary a,
.btn-secondary a,
.bg-dark a,
.btn-dark a {
  color: #fff;
}

/* Text sizes */
.lead {
  color: var(--dark-color);
  font-family: "Henderson BCG Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  /* 20.8px */
}

a {
  text-decoration: none;
  color: var(--light-color) !important;
  font-family: "Henderson BCG Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sm {
  color: var(--Grays-Gray-500, #696969);
  font-family: "Henderson BCG Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  /* 18.2px */
}

.smd {
  color: #000;
  font-family: "Henderson BCG Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.md {
  color: #000;
  font-family: "Henderson BCG Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  /* 33.6px */
}

.lg {
  color: #000;
  font-family: "Henderson BCG Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.xl {
  font-size: 4rem;
}

.bold {
  font-weight: 700;
}

/* Text colors */
.text-primary {
  color: #21bf61;
}

.text-secondary {
  color: var(--secondary-color);
}

.text-dark {
  color: var(--dark-color);
}

.text-light {
  color: var(--light-color) !important;
}

.text-lightDark {
  color: #a1a1a1;
}

.text-center {
  text-align: center;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  justify-content: center;
  align-items: baseline;
  height: 100%;
}

.grid-3 {
  grid-template-columns: repeat(3, 1fr);
}

.mt-40 {
  margin-top: 40px;
}

/* Margin */
.my-1 {
  margin: 1rem 0;
}

.my-2 {
  margin: 1.5rem 0;
}

.my-3 {
  margin: 2rem 0;
}

.my-4 {
  margin: 3rem 0;
}

.my-5 {
  margin: 4rem 0;
}

.m-1 {
  margin: 1rem;
}

.m-2 {
  margin: 1.5rem;
}

.m-3 {
  margin: 2rem;
}

.m-4 {
  margin: 3rem;
}

.m-5 {
  margin: 4rem;
}

/* Padding */
.py-1 {
  padding: 1rem 0;
}

.py-2 {
  padding: 1.5rem 0;
}

.py-3 {
  padding: 2rem 0;
}

.py-4 {
  padding: 3rem 0;
}

.py-5 {
  padding: 4rem 0;
}

.p-1 {
  padding: 1rem;
}

.p-2 {
  padding: 1.5rem;
}

.p-3 {
  padding: 2rem;
}

.p-4 {
  padding: 3rem;
}

.p-5 {
  padding: 4rem;
}

@media only screen and (max-width: 781px) {
  .md {
    font-size: 24px;
  }

  .smd {
    font-size: 18px;
  }

  .lead {
    font-size: 14px;
  }

  .header,
  .btn-container {
    .btn {
      width: 100%;
    }
  }
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px var(--primary-color);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px var(--primary-color);
  background-color: var(--primary-color);
}