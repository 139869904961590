.glance-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background: var(--secondary-color);
  padding: 40px;
  border-radius: 16px;

  .plant-detail {
    width: 20%;
  }

  .plant-details-container {
    width: 70%;
    row-gap: 50px;

    .glance {
      display: flex;
      flex-direction: column;

      @media only screen and (max-width: 600px) {
        flex-direction: row;
        grid-gap: 20px;

        .text-primary {
          margin-bottom: 0;
          margin-top: 0;
        }

        .img-desc {
          width: calc(100% - 40px);
        }
      }

      .image-card {
        width: 40px;
        height: 40px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}