.guide-container {
  width: 100%;
  height: auto;
  background-image: url("../../assets/images/guideBackground.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 60px 0px 30px;
  /* .container::before {
    position: absolute;
    bottom: 30%;
    left: 15%;
    width: 43px;
    height: 38px;
    content: url("../../assets/images/direction.png");
  } */
  .guide-wrapper {
    margin-top: 35px;
    display: flex;
    position: relative;
    gap: 20px;
    .guide-card {
      width: 315px;
      padding: 26px 50px 26px 26px;
      border-radius: 16px;
      background-image: url("../../assets/images/Rectangle.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      transition: all;
      height: 300px;
      h2 {
        color: var(--Grays-White, #fff);
        font-family: "Henderson BCG Sans";
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
      }
      .image-container {
        width: 50px;
        height: 50px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .guide-container {
    .guide-wrapper {
      flex-direction: column;
      .guide-card {
        width: 600px;
        height: 215px;
      }
    }
  }
}
