@import "/src/style/utilities/gobal.scss";

.checking {
  color: #000;
  /* 24 Bold - Content 02 */
  font-family: "Henderson BCG Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  /* 38.4px */
}