.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.55);
  backdrop-filter: blur(5px);
  z-index: 11;
  display: flex;
  justify-content: center;
}

.modal-container {
  position: fixed;
  top: 132px;
  background: white;
  border-radius: 16px;
  background: var(--Greys-0, #fff);
  box-shadow: 0px 5px 15px 2px rgba(0, 0, 0, 0.1);
  width: 720px;
  padding: 16px;
  .bar {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    width: 100%;
    height: 20px;
    p {
      margin-top: 0px;
      width: 30%;
      background-color: #edf1f2;
      height: 3px;
    }
  }
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 95%;
  .header-text {
    color: var(--Primary-Green-500, #197a56);
    font-family: "Henderson BCG Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 28.8px */
  }
}
.title {
  color: #424242;
  font-family: Poppins;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}
.closeIcon {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  img {
    width: 30px;
    height: 30px;
  }
}
.form-container {
  margin-top: 40px;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
  padding-bottom: 16px;
  input {
    width: 45%;
    border-radius: 8px;
    border: 1px solid var(--Greys-40, #bcc5c8);
    background: var(--Greys-0, #fff);
    height: 47px;
    outline: none;
    padding: 10px;
    color: var(--Greys-70, #646f73);
    font-family: "Henderson BCG Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 100% */
  }
  .text-area {
    display: flex;
    flex-direction: column;
    width: 94%;
    label {
      color: var(--Greys-90, #2c3235);
      /* Paragraph/body 1 */
      font-family: "Henderson BCG Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 19.2px */
    }
    textarea {
      margin-top: 6px;
      border-radius: 8px;
      border: var(--L, 1px) solid var(--Greys-30, #d5dadc);
      background: var(--Greys-0, #fff);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      color: var(--Greys-60, #717b7f);
      /* Paragraph/body 1-italic */
      font-family: "Henderson BCG Sans";
      font-size: 16px;
      font-style: italic;
      font-weight: 400;
      line-height: 120%;
      outline: none;
      padding: 10px;
    }
  }
  .btn-wraper {
    display: flex;
    justify-content: flex-end;
    width: 94%;
    .btn:disabled {
      cursor: not-allowed;
      background-color: #b1b1b1;
      color: #696969;
    }
  }
}
