.testimonials-container {
  padding: 65px 0px;
  position: relative;
  .container {
    .border-line {
      position: absolute;
      height: 68px;
      width: 2px;
      background: var(--lightDark);
      left: 50%;
      bottom: 40%;
    }
    .testimonials-wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 32px;
      .testimonial {
        width: 43%;
        display: flex;
        gap: 17px;
        height: 200px;
        .font-16 {
          font-size: 16px;
        }
        .description {
          color: var(--Grays-Gray-700, #323232);
          font-family: "Henderson BCG Sans";
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 24px */
        }
      }
    }
    /* .testimonial:nth-child(1):before {
      width: 43px;
      height: 38px;
      content: url("../../assets/images/stingone.png");
    }
    .testimonial:nth-child(2)::after {
      position: absolute;
      top: 37%;
      right: 6%;
      width: 43px;
      height: 38px;
      content: url("../../assets/images/direction.png"); */
  }
}

/* .testimonials-container::before {
  position: absolute;
  bottom: 80%;
  right: 3%;
  width: 400px;
  height: 350px;
  content: url("../../assets/images/Ellipse.png");
} */
