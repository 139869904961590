.video-container {
  margin-top: 70px;
  width: 100%;

  .container {
    .video-wrapper {
      padding: 0px 40px;
      display: grid;
      grid-template-columns: 28% 72%;
      gap: 40px;
      position: relative;

      @media screen and (max-width: 600px) {
        gap: 20px;
        background-image: url(../../assets/images/ellipsedesignmobile.png);
        background-size: 100% 100%;
      }

      .grey-label-pane {
        width: 100%;

        @media screen and (min-width: 600px) {
          background-color: #ededed95;
          padding: 20px 30px;
          position: absolute;
          bottom: 90px;
          left: 40px;

          .smd {
            color: #197a56 !important;
            width: 27%;
          }
        }

        .lead {
          @media screen and (min-width: 600px) {
            display: none;
          }

          @media screen and (max-width: 600px) {
            border-left: 3px solid #00000020;
            padding-left: 10px;
            line-height: 20px;
            margin-bottom: 30px;
          }
        }
      }

      .videos-header {
        position: relative;
        background-image: url(../../assets/images/lvectors.png);
        background-size: 100% 100%;

        @media screen and (max-width: 600px) {
          background: none;
        }

        .font-wight {
          font-weight: 700;
          line-height: 145%;
        }

        .line-hight {
          line-height: 200%;
        }

        .ellipsedesign {
          display: flex;
          position: absolute;

          @media screen and (min-width: 600px) {
            top: 33px;
            right: 0;
            left: 8px;
            width: 700px;
            display: flex;
            position: absolute;
          }

          @media screen and (max-width: 600px) {
            display: none;
          }
        }

        .ellipsedesignmobile {
          @media screen and (min-width: 600px) {
            display: none;
          }

          @media screen and (max-width: 600px) {
            transform: rotate(-31.2deg);
            width: 419px;
            height: 159px;
            // display: flex;
            display: none;
            position: absolute;
          }
        }
      }

      .video-wrapper-mac {
        position: relative;
        margin-top: -95px;

        @media screen and (max-width: 600px) {
          margin-top: -54px;
        }

        .mac {
          width: 100%;
          position: absolute;
          max-width: 921px;
          height: 780px;

          @media screen and (max-width: 600px) {
            max-width: 400px;
            height: 300px;
          }
        }

        .video-player {
          position: relative;
          /* padding-bottom: 56.25%; */
          /* padding-bottom: 70.25%; */
          /* 16:9 aspect ratio */
          height: 580px;
          overflow: hidden;

          @media screen and (max-width: 600px) {
            height: 270px;
          }

          iframe {
            position: absolute;
            top: 80px;
            left: 21px;
            width: 879px;
            height: 438px;

            @media screen and (min-width: 800px) and (max-width: 1280px) {
              width: 760px;
            }

            @media screen and (max-width: 600px) {
              width: 330px;
              height: 170px;
              top: 30px;
              left: 10px;
            }
          }
        }
      }
    }
  }

  .imageList-list {
    background-color: var(--secondary-color);
    position: relative;
    margin-top: 55px;

    @media screen and (max-width: 600px) {
      margin-top: 0;
    }

    .images-wrapper {
      max-width: 1380px;
      margin: 0 auto;
      overflow: auto;
      padding: 55px 40px 45px;
      padding-left: 80px;
      display: flex;
      gap: 15px;

      .active {
        background-color: #1c7a5721;
        padding: 0 10px;
        border-radius: 10px;

        img {
          border: 5px solid #21bf61;
          border-radius: 10px;
        }

        p {
          border: none !important;
        }
      }

      .video-image {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 200px;
        cursor: pointer;
        flex: 1;
        img {
          width: 65px;
          height: 45px;
        }

        p {
          padding: 0px 16px;
          border-right: 1px solid var(--lightDark);
          font-weight: 700;
          word-break: break-word;
        }

        p:nth-child(5) {
          border: none;
        }
      }

      .video-image:nth-child(5) > p {
        border: none;
      }
    }
  }
}
