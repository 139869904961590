.header {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 72px;
  padding-right: 45px;
  background: var(--light-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.07);
  z-index: 1;
  .logo-container {
    width: 72px;
    height: 72px;
    background-color: var(--primary-color);
    display: grid;
    place-items: center;
    img {
      width: 51px;
      height: 21px;
    }
  }
}
